@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap");

* {
  font-family: "Source Sans Pro", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


img{
  max-width: 100%;
}