.Form {
  width: 90%;
  max-width: 550px;
  margin: 6em auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Form img {
  width: 12em;
}
.Form h2 {
  font-size: 2em;
  text-align: center;
  margin: 0.5em 0 0.5em;
  color: #ffffff;
}
.Form p {
  font-size: 1.2em;
  width: 80%;
  text-align: center;
  color: #5a5a5a;
  margin: 0 auto;
}
.Form div {
  width: 100%;
  position: relative;
  margin-top: 2em;
  padding-top: 0.5em;
}
.Form div label {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 1em;
  color: #5a5a5a;
  background-color: #000000;
  padding: 0 0.5em;
}
.Form div textarea,
.Form div input {
  width: 100%;
  display: block;
  outline: none;
  border: 1px solid #5a5a5a;
  background: transparent;
  border-radius: 7px;
  padding: 1em;
  font-size: 1em;
  box-sizing: border-box;
  color: #ffffff;
}

.Form div textarea::placeholder {
  color: #5a5a5add;
}

.Form div .errorMessage {
  font-size: 1em;
  color: rgb(255, 57, 57);
}
.Form button {
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 1em;
  width: 100%;
  margin-top: 2em;
  cursor: pointer;
  font-size: 1em;
  background-color: #007bff;
  color: #ffffff;
}
.Form button.compact {
  width: fit-content;
  display: block;
  margin: 1em auto;
}
.Form button:disabled {
  background-color: rgba(0, 123, 255, 0.3);
  color: #5a5a5add;
}
.Form .fadeIn {
  transition: opacity 0.2s linear;
}

.Form .fadeOut {
  transition: opacity 0.2s linear;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .Form h2 {
    font-size: 1.5em;
  }
  .Form p {
    width: 100%;
  }
}
